@charset "UTF-8";
body {
  background: #fff;
  color: #333;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 1.1rem;
  overflow-x: hidden; }

img {
  display: block;
  width: 100%;
  height: auto; }

a {
  display: inline; }

.inner {
  margin: auto;
  width: 94%; }

/*----- android -----*/
/*----- header -----*/
/*----- fv -----*/
/*-----  -----*/
/*-----  -----*/
/*-----  -----*/
/*-----  -----*/
/*-----  -----*/
/*----- footer -----*/
/*----- form -----*/
.formInputFrame, #form .formTable td input[type="text"],
#form .formTable td select,
#form .formTable td textarea {
  background: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  box-shadow: inset 0px 3px 3px 0px rgba(4, 0, 0, 0.08);
  padding: .7rem;
  width: 100%;
  resize: none; }

#form input[type="image"] {
  width: 100%;
  height: auto; }

#form #header {
  position: static; }

#form .formTable {
  margin-bottom: 7%;
  width: 100%; }
  #form .formTable .error {
    color: #df3434;
    font-size: 1.1rem;
    margin-bottom: .5rem; }
  #form .formTable .req th label {
    display: inline-block;
    margin-right: .3rem;
    vertical-align: middle; }
  #form .formTable .req th::after {
    content: '必須';
    color: #df3434;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    vertical-align: middle; }
  #form .formTable tr,
  #form .formTable th,
  #form .formTable td {
    display: block; }
  #form .formTable tr {
    padding: 1rem 0; }
    #form .formTable tr:not(:last-of-type) {
      border-bottom: 1px solid #ededed; }
    #form .formTable tr + tr {
      border-top: 1px solid #b6b6b6; }
  #form .formTable th {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: .8rem; }
    #form .formTable th label {
      font-weight: bold; }
    #form .formTable th .caution {
      font-size: 1rem; }
  #form .formTable td {
    background: #fff;
    /*checkbox周り*/
    /*ここまでcheckbox周り*/ }
    #form .formTable td input[type="checkbox"] {
      display: none; }
      #form .formTable td input[type="checkbox"] + label {
        background: #fff8d0;
        cursor: pointer;
        color: #474747;
        display: block;
        font-weight: bold;
        padding: 1em;
        padding-left: 4rem;
        width: 100%;
        position: relative;
        user-select: none;
        transition: .2s; }
        #form .formTable td input[type="checkbox"] + label:hover {
          background-color: #f3f6d8; }
          #form .formTable td input[type="checkbox"] + label:hover::before {
            border-color: #53b300; }
        #form .formTable td input[type="checkbox"] + label::before {
          content: '';
          background: #fff;
          border: 1px solid #c5c5c5;
          display: block;
          width: 2rem;
          height: 2rem;
          position: absolute;
          left: 1rem;
          top: 50%;
          margin-top: -1rem;
          transition: .2s; }
        #form .formTable td input[type="checkbox"] + label::after {
          content: '';
          border-right: 3px solid #53b300;
          border-bottom: 3px solid #53b300;
          display: block;
          opacity: 0;
          width: 1rem;
          height: 1.6rem;
          position: absolute;
          left: 1.6rem;
          top: 50%;
          margin-top: -1rem;
          transform: rotate(45deg);
          transition: .2s; }
    #form .formTable td input[type="checkbox"]:checked + label::after {
      opacity: 1; }
    #form .formTable td select {
      appearance: none;
      background-image: url(../img/form_selectArrow.png);
      background-repeat: no-repeat;
      background-position: 97% center;
      background-size: auto 77%; }
    #form .formTable td hr {
      border: none;
      display: block;
      height: 1rem; }
    #form .formTable td input + .text {
      margin: 1rem 0; }
    #form .formTable td .text {
      font-size: 1.1rem;
      line-height: 1.5; }
  #form .formTable .num select {
    margin: 1rem 0; }
    #form .formTable .num select:last-of-type {
      margin-bottom: 0; }
  #form .formTable .tel td .text {
    margin-bottom: 0; }

#form #nextBtn {
  margin: auto;
  width: 88%; }

/*----- confirm -----*/
#form.confirm .formTable td {
  line-height: 1.9;
  padding-left: .5em;
  min-height: 3rem; }

#form.confirm #sendBtn {
  margin: 0 auto 6%;
  width: 80%; }

#form.confirm #prevBtn {
  margin: auto;
  width: 80%; }

/*----- thanks -----*/
#form.thanks #formFrame {
  margin-bottom: 15%; }
  #form.thanks #formFrame h2 {
    color: #0199ff;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center; }

#form.thanks #goTop {
  margin: auto;
  width: 74%; }
  #form.thanks #goTop a {
    color: #2870de;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline; }

/*------------------------------------  custom-css ---------------------------------------*/
p {
  line-height: 1.5em; }

#header .title-header {
  padding: 0.8rem 0;
  background: #90ba2a; }
  #header .title-header p {
    font-size: 1.75rem;
    color: #fff; }

#header .list-header {
  display: flex; }

#header .logo {
  padding-top: 1.125rem;
  padding-right: 1rem;
  padding-left: 0.5rem;
  flex: 1.1; }

#header .header-mail {
  flex: 2; }
  #header .header-mail img {
    position: relative;
    top: -3px;
    right: -6px; }

#header .header-tel {
  flex: 2; }
  #header .header-tel img {
    position: relative;
    top: -3px; }

#fv {
  margin-top: .6rem;
  padding-bottom: 2.1875rem; }
  #fv .des-fv {
    padding-top: .5rem;
    text-align: center;
    color: #4d3833;
    font-size: 1.75rem;
    line-height: 1.5em;
    padding-bottom: 2rem; }
  #fv .info_button {
    margin-top: 1.25rem;
    padding: 0 1%; }

#trouble {
  padding-bottom: 1.5625rem; }
  #trouble .list-trouble {
    padding: 0 1.375rem;
    margin-top: 2.5rem; }
    #trouble .list-trouble li {
      margin-bottom: 1.8125rem; }
      #trouble .list-trouble li img {
        width: initial;
        max-width: 100%; }
        #trouble .list-trouble li img.trouble_text2 {
          width: 90%; }
        #trouble .list-trouble li img.trouble_text3 {
          width: 73%; }
        #trouble .list-trouble li img.trouble_text5 {
          width: 77.5%; }
        #trouble .list-trouble li img.trouble_text6 {
          width: 90.1%; }
        #trouble .list-trouble li img.trouble_text7 {
          width: 93%; }

#cause {
  margin-bottom: 3.75rem; }
  #cause .cause-content {
    background: #ffe5d9;
    border: 0.375rem solid #f5d8ac;
    padding: 2.5rem 1.0625rem 2.5rem 1.0625rem; }
  #cause .cause-content-title {
    font-size: 1.75rem;
    color: #e4004d;
    font-weight: bold;
    text-align: justify;
    padding: 0 1.5rem;
    margin-bottom: 1rem; }
  #cause .des-cause {
    padding: 0 1.5rem;
    color: #4d3833;
    font-size: 1.75rem;
    text-align: justify;
    margin-bottom: 2.5rem; }
    #cause .des-cause .color {
      color: #e4004d; }
  #cause .box-cause {
    background: #fdf2ed;
    border: 0.125rem solid #fff;
    padding: 1.1875rem 1.1875rem 1.875rem;
    margin-bottom: 1.25rem; }
    #cause .box-cause .des-box-cause {
      font-size: 1.65rem;
      color: #4d3833;
      margin-top: .6rem;
      margin-bottom: .7rem;
      padding-left: 1rem;
      padding-right: 2rem; }
    #cause .box-cause .list-content-box {
      border: 0.0625rem solid  #ef8802;
      background: #fff; }
      #cause .box-cause .list-content-box h4 {
        background: #ef8802;
        color: #fff;
        font-weight: bold;
        font-size: 1.75rem;
        text-align: center;
        padding-top: .5rem;
        padding-bottom: .4rem; }
      #cause .box-cause .list-content-box ul {
        padding: 1.1rem 1.5rem .3rem; }
        #cause .box-cause .list-content-box ul li p {
          line-height: 1;
          font-size: 1.75rem;
          color: #4d3833;
          padding-bottom: .9rem; }

#medical {
  padding-bottom: 1.25rem;
  background: #d3f1fa; }
  #medical h2 {
    margin-bottom: 2.1875rem; }
  #medical .box-medical {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    padding-bottom: 1rem;
    background: #fff;
    margin-bottom: 2.0625rem;
    display: inline-block;
    width: 100%; }
    #medical .box-medical h3 {
      border-top-left-radius: 1.25rem;
      border-top-right-radius: 1.25rem;
      padding: 1.45rem .5rem 1.4rem;
      margin-bottom: 2.0625rem;
      text-align: center;
      font-weight: bold;
      font-size: 1.8rem;
      color: #fff; }
    #medical .box-medical .img-top {
      margin-bottom: 1.5625rem;
      padding: 0 1.25rem; }
    #medical .box-medical .des-box-medical {
      font-size: 1.75rem;
      padding: 0 1.25rem;
      margin-bottom: 1.0625rem; }
    #medical .box-medical .list-text {
      font-size: 1.75rem;
      text-align: justify;
      padding: 1rem 1.25rem;
      margin-bottom: 1.4375rem; }
    #medical .box-medical .d-contai {
      display: flex;
      margin-bottom: 2.0625rem; }
      #medical .box-medical .d-contai .col-50 {
        width: 50%;
        margin-right: 13px; }
        #medical .box-medical .d-contai .col-50:last-child {
          margin-right: 0; }
      #medical .box-medical .d-contai .title-col {
        color: #fff;
        font-size: 1.75rem;
        padding: .7rem .5rem;
        text-align: center; }
      #medical .box-medical .d-contai p {
        font-size: 1.75rem;
        color: #4d3833;
        text-align: center;
        padding: .7rem .5rem; }
    #medical .box-medical .point-text {
      display: inline-block;
      width: 100%;
      padding: 0 1.25rem; }
      #medical .box-medical .point-text p {
        font-size: 1.75rem;
        padding-bottom: 1.375rem;
        text-align: justify; }
        #medical .box-medical .point-text p .color {
          color: #e4004d; }
        #medical .box-medical .point-text p img {
          width: 25%;
          float: left;
          padding-right: 1rem; }
  #medical .cl1 {
    border: 0.125rem solid #5ec8e1; }
    #medical .cl1 h3 {
      background: #5ec8e1; }
    #medical .cl1 .des-box-medical {
      color: #5ec8e1; }
    #medical .cl1 .list-text {
      background: #e8f3f6; }
    #medical .cl1 .d-contai .title-col {
      background: #5ec8e1; }
    #medical .cl1 .d-contai .col-50 {
      border: 0.0625rem solid #5ec8e1; }
  #medical .cl2 {
    border: 0.125rem solid #ff7b89; }
    #medical .cl2 h3 {
      background: #ff7b89; }
    #medical .cl2 .des-box-medical {
      color: #ff7b89; }
    #medical .cl2 .list-text {
      background: #fcf2f3; }
    #medical .cl2 .d-contai .title-col {
      background: #ff7b89; }
    #medical .cl2 .d-contai .col-50 {
      border: 0.0625rem solid #ff7b89; }
  #medical .cl3 {
    border: 0.125rem solid #a87aea; }
    #medical .cl3 h3 {
      background: #a87aea; }
    #medical .cl3 .des-box-medical {
      color: #a87aea; }
    #medical .cl3 .list-text {
      background: #f2edf9; }
    #medical .cl3 .d-contai .title-col {
      background: #a87aea; }
    #medical .cl3 .d-contai .col-50 {
      border: 0.0625rem solid #a87aea; }
  #medical .cl4 {
    border: 0.125rem solid #f88f26; }
    #medical .cl4 h3 {
      background: #f88f26; }
    #medical .cl4 .des-box-medical {
      color: #f88f26; }
    #medical .cl4 .list-text {
      background: #fff6e9; }
    #medical .cl4 .d-contai .title-col {
      background: #f88f26; }
    #medical .cl4 .d-contai .col-50 {
      border: 0.0625rem solid #f88f26; }
  #medical .cl5 {
    border: 0.125rem solid #91d977; }
    #medical .cl5 h3 {
      background: #91d977; }
    #medical .cl5 .des-box-medical {
      color: #91d977; }
    #medical .cl5 .d-contai .title-col {
      background: #91d977; }
    #medical .cl5 .d-contai .col-50 {
      border: 0.0625rem solid #91d977; }

#info {
  padding-top: 3rem;
  padding-bottom: 3.75rem; }
  #info h2 {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 1.875rem; }
    #info h2 img {
      width: initial;
      margin: 0 auto;
      max-width: 100%; }
  #info .info_button {
    margin-top: 1.2rem;
    padding: 0 1%; }

#clinic {
  margin-bottom: 5rem; }
  #clinic h2 {
    margin-bottom: 2.8125rem; }
  #clinic .des-info {
    background: #fff0d1;
    border: 0.25rem solid #f5d8ac;
    padding: 1.1875rem 2.8125rem;
    margin: 1.75rem 1.25rem 2.1875rem 1.25rem; }
    #clinic .des-info .title-box {
      font-weight: bold; }
    #clinic .des-info p {
      font-size: 1.75rem;
      color: #4d3833;
      text-align: justify; }
  #clinic .text-clinic {
    font-size: 1.75rem;
    color: #4d3833;
    padding: 0 2.375rem;
    margin-bottom: 3.125rem;
    text-align: justify;
    line-height: 1.4; }
  #clinic .clinic_image2 {
    margin-top: -3.125rem; }
  #clinic .point-clinic {
    padding: 0 1.5rem;
    margin-top: 2.5rem; }
    #clinic .point-clinic h3 {
      margin-bottom: 0.5rem; }
    #clinic .point-clinic p {
      font-size: 1.75rem;
      color: #4d3833; }
  #clinic .clinic_image3 {
    margin-top: 5rem; }

.m_t_50 {
  margin-top: 3.125rem; }

#access {
  margin-bottom: 4.0625rem; }
  #access h2 {
    margin-bottom: 3.125rem; }
  #access .map-access {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 0 3.75rem;
    height: 250px; }
  #access .box-content-access {
    margin-bottom: 2.5rem;
    padding: 0 2.5rem; }
    #access .box-content-access h3 {
      font-weight: bold;
      color: #4d3833;
      border-bottom: 0.125rem solid #4d3833;
      padding-bottom: .2rem;
      margin-bottom: 1.2rem;
      font-size: 2.1rem; }
    #access .box-content-access p {
      font-size: 1.75rem;
      color: #4d3833; }
  #access .info_button {
    padding: 0 1%;
    margin-top: 1.25rem; }

footer {
  background: #eaf1d9;
  text-align: center;
  padding-bottom: 3.75rem;
  padding-top: 2.5rem; }
  footer .logo-footer {
    width: initial;
    display: block;
    margin: 0 auto; }
  footer p {
    color: #4d3833; }
  footer .text-des-footer {
    font-size: 1.75rem;
    margin-top: 3rem;
    margin-bottom: 3.4375rem;
    padding: 0 1rem; }
  footer .text-des-footer2 {
    font-size: 1.4375rem; }

#copyright {
  background: #bedd72;
  text-align: center;
  padding: 1.26rem 0; }
  #copyright p {
    color: #fff;
    font-size: 1.1875rem; }

#over {
  content: "";
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  opacity: 0.75;
  display: none;
  transition: 0.5s ease-in-out; }

#popup {
  transition: 0.5s ease-in-out;
  display: none;
  position: absolute;
  padding: 0 1rem;
  z-index: 8;
  top: 50%;
  transform: translateY(-50%); }
  #popup #close img {
    width: initial;
    float: right;
    padding-right: .4rem;
    padding-bottom: .7rem; }

#footer-fixed {
  padding-top: 10rem;
  bottom: 0; }
  #footer-fixed ul {
    display: flex; }
